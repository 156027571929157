import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import theme from '../../utils/themeconstants';
import Link from '../elements/link';

const JobAccordion = styled.div`
  width: 100%;
  &:first-child{
    padding-top: 0;
  }
`;

const JobContainer = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  margin-top: 1.5em;
border-radius: ${props => (props.active ? `${theme.radius.md}px ${theme.radius.md}px 0 0` : `${theme.radius.md}px`)};
  background-color: rgba(246,249,252,1);
  transition: background-color .2s ease-out;
  cursor: pointer;
 &:hover{
    background-color: ${props => (props.active ? 'rgba(246,249,252,1)' : 'rgba(246,249,252,0.5)')} ;
  }
`;

const PlusButton = styled.div`
  background-color: ${props => (props.active ? theme.colors.primary : theme.colors.bgPrimary)};
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 24px;
  text-align: center;
  font-size: 36px;
  position: relative;
  transition: background 0.2s ease-out;
  &:after{
    ${props => (props.active ? "content:'-';" : "content:'+';")}
    color: ${props => (props.active ? 'white' : 'black')};
    position: relative;
    top: ${props => (props.active ? '-9px' : '-7px')};
  }
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    width: 32px;
    height: 32px;
    min-width: 32px;
    font-size: 28px;
  }
`;

const Title = styled.h4`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    font-size: 18px;
  }
`;

const Location = styled.span`
  font-weight: normal;
  font-size: 17px;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    font-size: 14px;
  }
`;

const Tagline = styled.span`
  font-weight: lighter;
  font-size: 0.8em;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    font-size: 11px;
  }
`;

const JobDescription = styled.div`
  padding: ${props => (props.active ? '0.5em 2em 2em 2em' : '0 2em')};
  background-color: rgba(246,249,252,1);
  line-height: 28px;
  height: ${props => (props.active ? 'auto' : '0')};
  transition: height 0.4s ease-in-out;
  overflow: hidden;
  border-radius: 0 0 ${theme.radius.md}px ${theme.radius.md}px;
  ul{
    list-style-type: none;
    li{
      margin: 0;
      &:before {
        content:"·";
        font-size: 20px;
        vertical-align: middle;
        line-height: 20px;
        padding-right: 0.5em;
      }
    }
  }
`;


class Joboffers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: null,
    };
  }

  activateJob = (title) => {
    const { active } = this.state;
    if (active === title) {
      this.setState({
        active: null,
      });
    } else {
      this.setState({
        active: title,
      });
    }
  };

  render() {
    const { active } = this.state;
    return (
      <StaticQuery
        query={graphql`
        {
          allJoboffersJson {
            edges {
              node {
                id
                title
                subtitle
                location
                jobintro
                role
                expectations {
                  key
                  value
                }
              }
            }
          }
      }
      `}
        render={data => (
          <JobAccordion>
            {data.allJoboffersJson.edges.map(({ node }) => (
              <React.Fragment key={node.id}>
                <JobContainer
                  onClick={() => this.activateJob(node.title)}
                  active={active === node.title}
                >
                  <Title>
                    {node.title}
                    <Location>
                      &nbsp;in&nbsp;
                      {node.location}
                    </Location>
                    <br />
                    <Tagline>{node.subtitle}</Tagline>
                  </Title>
                  <PlusButton active={active === node.title} />
                </JobContainer>
                <JobDescription active={active === node.title}>
                  <h4>
                    The Role
                    <span role="img" aria-label="computer">
                      &nbsp;💻
                    </span>
                  </h4>
                  <p>
                    {node.jobintro}
                  </p>
                  <p>
                    Every day, you&apos;ll:
                  </p>
                  <ul>
                    {node.role.map(item => (
                      <li key={item}>
                        {item}
                      </li>
                    ))}
                  </ul>
                  <h4>Sound Familiar?
                    <span role="img" aria-label="praiseHands">
                     &nbsp;🙌
                    </span>
                  </h4>
                  <p>
                    What we’re looking for:
                  </p>
                  <ul>
                    {
                      node.expectations.map(expectation => (
                        <li key={expectation.key}>
                          <strong>{`${expectation.key}: `}</strong>{expectation.value}
                        </li>
                      ))
                    }
                    <li>
                      <strong>Empathy: </strong>In order to build a great product and a great company, we all have to be able to put ourselves in each other’s shoes and our users’ shoes. We strive to assume best intentions, promote positivity through our products and our actions, and to be there when our teammates need support.
                    </li>
                    <li>
                      <strong>Perspective: </strong>We value diverse backgrounds and perspectives - your voice and beliefs will help us all be better and shape the future of the company. Don’t be afraid to take risks, challenge us, and challenge your own beliefs!
                    </li>
                  </ul>
                  <p>
                    We believe in the strength of diverse work environments and welcome
                     applications from people of different backgrounds, experiences, abilities
                     and perspectives.
                  </p>
                  <p>
                    Sounds like your dream job? {' '} <Link outward href="mailto:jobs@tmrow.com">Apply via email</Link>
                  </p>
                </JobDescription>
              </React.Fragment>
            ))}
          </JobAccordion>
        )}
      />
    );
  }
}

export default Joboffers;
