import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSlack, faGithub,
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelopeOpenDollar,
  faIslandTropical,
  faSalad,
  faMapMarkedAlt,
  faBooks,
  faCampground,
  faChessClock,
  faGlobeAfrica,
} from '@fortawesome/pro-duotone-svg-icons';

import theme from '../../utils/themeconstants';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Link from '../../components/elements/link';
import ContentContainer from '../../components/elements/pageContentContainer';
import JobOffers from '../../components/joboffers';
import Section from '../../components/elements/section';
import Stage from '../../components/pagecomponents/jobs_stage';


const PageContent = styled.div`
  position: relative;
`;

const Benefit = styled.div`
  display: flex;
  padding: ${theme.space.md}px;
  margin: 0 0 16px 0;
  p {
    margin: 0;
    font-weight: 600;
    line-height: 22px;
    padding: 0 0 0 16px;
  }
`;

const WorkContainer = styled.div`
  padding: ${theme.space.md}px;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    padding: 0;
  }
`;


const JobPage = () => (
  <Layout parent="company" dark>
    <SEO title="Tomorrow - Jobs" keywords={['tomorrow', 'carbon impact', 'company', 'jobs', 'startup', 'technology']} />
    <Stage />
    <PageContent>
      <ContentContainer>
        <Section title="Build tech that makes an impact">
          {/* What is Tomorrow and why it's exciting to work with us */}
          <Col md={8} offset={{ md: 2 }}>
            <p>
              Our products are used by millions of people around the world every year. Most notably, <Link outward href="https://www.electricitymap.org">electricityMap </Link>
               is used by countless individuals, businesses, NGOs, and companies to better understand and predict the carbon footprint of their electricity consumption.
              <br /><br />
              Join us in building tech products that empower people and organisations to 
               understand and reduce their carbon footprint. 
              <strong>
                {' '} Your life’s work should be something that matters.
              </strong>
            </p>
          </Col>
        </Section>

        <Section title="Working thoughtfully">
          {/* How we work */}
          <Col md={8} offset={{ md: 2 }}>
            <p>
              Building a sustainable future means having a 
              <b> 
                {' '}sustainable approach to work 
              </b>
              . We aim to create a flexible, calm environment that puts
              everyone in a position to do their best work.
            </p>
          </Col>
          <GridContainer style={{ marginTop: 2 * theme.space.lg }}>
            <Row>
              <Col md={6}>
                <WorkContainer>
                  <h3>Deep work</h3>
                  <p>
                    <b>We respect each other&apos;s time</b> and strive to create an environment where everyone is empowered to do their best, most focused work.
                  </p>
                </WorkContainer>
              </Col>
              <Col md={6}>
                <WorkContainer>
                  <h3>Growth opportunities</h3>
                  <p>
                    As a member in a small but growing team, you&apos;ll have the
                     opportunity to <b>be in the driver’s seat</b>, actively shaping your role as we grow together.
                    <br />
                  </p>
                </WorkContainer>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <WorkContainer>
                  <h3>Openness</h3>
                  <p>We aim for an environment of open-mindedness and psychological safety - <b>where everyone feels welcome to share ideas and views</b>, no matter how outside of the box they may seem.
                  </p>
                  
                </WorkContainer>
              </Col>
              <Col md={6}>
                <WorkContainer>
                  <h3>Purpose</h3>
                  <p>
                    Climate change is scary. There&apos;s no better medicine to 
                     alleviate one&apos;s anxiety than to
                    <b>
                      wake up every morning knowing you&apos;re 
                       doing your best to fix it
                    </b>. 
                  </p>
                </WorkContainer>
              </Col>
            </Row>
          </GridContainer>
        </Section>
        
        <Section title="Benefits">
          <GridContainer>
            <Row>
              <Col md={4}>
                <Benefit>
                  <FontAwesomeIcon size="lg" color={theme.colors.red} icon={faEnvelopeOpenDollar} />
                  <p>Competitive salary and startup equity</p>
                </Benefit>
              </Col>
              <Col md={4}>
                <Benefit>
                  <FontAwesomeIcon size="lg" color={theme.colors.blue} icon={faIslandTropical} />
                  <p>Five weeks paid time off</p>
                </Benefit>
              </Col>
              <Col md={4}>
                <Benefit>
                  <FontAwesomeIcon size="lg" color={theme.colors.green} icon={faSalad} />
                  <p>Lunch and snacks five times a week</p>
                </Benefit>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Benefit>
                  <FontAwesomeIcon size="lg" color={theme.colors.violet} icon={faMapMarkedAlt} />
                  <p>Work in one of the most livable cities in the world</p>
                </Benefit>
              </Col>
              <Col md={4}>
                <Benefit>
                  <FontAwesomeIcon size="lg" color="#F98432" icon={faBooks} />
                  <p>Any book you want policy</p>
                </Benefit>
              </Col>
              <Col md={4}>
                <Benefit>
                  <FontAwesomeIcon size="lg" color="#F627E8" icon={faCampground} />
                  <p>Quarterly team offsites</p>
                </Benefit>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Benefit>
                  <FontAwesomeIcon size="lg" color="#F9D332" icon={faChessClock} />
                  <p>Flexible hours, deep work tuesday, remote days.</p>
                </Benefit>
              </Col>
              <Col md={4}>
                <Benefit>
                  <FontAwesomeIcon size="lg" color="#01D0D0" icon={faGlobeAfrica} />
                  <p>Work every day to help save the planet</p>
                </Benefit>
              </Col>
              <Col md={4} />
            </Row>
          </GridContainer>
        </Section>

        <Section id="joboffers" title="Current Job Opportunities">
          <JobOffers />
        </Section>

        <Section title="Get in touch">
          <GridContainer>
            <Row>
              <Col md={6}>
                <p>
                  Didn&apos;t find a role that fits? Send us an &nbsp;
                  <Link outward href="mailto:jobs@tmrow.com">email</Link>
                  &nbsp; with what you have built in the past,
                  and a description of what you&apos;d ideally like to work on.
                </p>
              </Col>
              <Col md={6}>
                <p>
                  You can also help by contributing to our &nbsp;
                  <Link outward href="https://github.com/tmrowco/" target="_blank">
                    <FontAwesomeIcon size="xs" color={theme.colors.yellow} icon={faGithub} />
                    &nbsp;open source projects.
                  </Link>
                  &nbsp; Come say hi on our &nbsp;
                  <Link outward href="http://slack.tmrow.co" target="_blank">
                    <FontAwesomeIcon size="xs" color={theme.colors.yellow} icon={faSlack} />
                    &nbsp;slack
                  </Link>
                  &nbsp; channel if you have any questions.
                </p>
              </Col>
            </Row>
          </GridContainer>
        </Section>
      </ContentContainer>
    </PageContent>
  </Layout>
);

export default JobPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image{
              childImageSharp {
                fluid(quality: 80, maxWidth: 672) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
