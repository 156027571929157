import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import theme from '../../utils/themeconstants';
import StageContentContainer from '../elements/stageContentContainer';
import Button from '../elements/button';

const StageContainer = styled.div`
  overflow: hidden;
  height: 100vh;
  position: relative;
  width: 100%;
  background-size: cover;
  background-color: transparent;
  z-index: -2;
`;

const StageImage = styled.div`
  height: 100vh;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StageTitle = styled.div`
  height: 100vh;
  width: 60%;
  display:flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: ${theme.space.lg}px;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    width:100%;
    padding: 0;
  }
  h1{
    text-align: center;
    text-shadow: 0 0 4px rgba(0,0,0,0.15);
  }
  h3 {
    font-weight: lighter;
  }
`;

const Shape = styled.div`
    position: absolute;
    border-radius: 15px;
    box-shadow: 0 0 120px rgba(0,0,0,0.15);
    background-color: ${theme.colors.bgSecondary};
    overflow: hidden;
  &.shape1{
    width: 40vw;
    height: 40vw;
    max-width: 460px; 
    max-height: 460px;
    transform: rotate3d(1,0,1, 19deg);
    @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      display: none;
    }
  }
`;


const StageJobs = () => (
  <StaticQuery
    query={graphql`
    query {
      bgImg: file(relativePath: { eq: "jobstage-background.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 640) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }`}
    render={data => (
      <StageContainer>
        <StageContentContainer style={{ display: 'flex', alignItems: 'center' }}>
          <StageTitle>
            <h2>
              The best minds of our generation are thinking about
              {' '}
              <span style={{ textDecoration: 'line-through' }}>how to make people click on ads</span>
              <span style={{ color: theme.colors.red }}> climate change</span>
            </h2>
            <Button justify="flex-start" to="jobs/#joboffers" color={theme.colors.red}>
              See open positions
            </Button>
          </StageTitle>
          <StageImage>
            <Shape alt="tech1" className="shape shape1">
              <Img style={{ height: '100%', width: 'auto' }} fluid={data.bgImg.childImageSharp.fluid} />
            </Shape>
          </StageImage>
        </StageContentContainer>
      </StageContainer>
    )}
  />
);


export default StageJobs;
